@font-face {
    font-family: 'LG Smart Bold';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'LG Smart Bold';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold_Italic.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold_Italic.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Bold_Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: 'LG Smart Light';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Light.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Light.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Light.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'LG Smart Regular';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'LG Smart Regular';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular_Italic.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular_Italic.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_Regular_Italic.woff) format("woff");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: 'LG Smart SemiBold';
    font-display: swap;
    src: local("☺"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_SemiBold.eot?#iefix) format("embedded-opentype"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_SemiBold.woff2) format("woff2"),
        url(https://sso.us.lg.com/lg5-common/webfonts/LG_Smart_SemiBold.woff) format("woff");
    font-weight: 400;
    font-style: normal
}


.w-10 {
    width: 5% !important;
}

.background {
    background-color: #f0ece4 !important;
    border: 1px solid #e4e4e4 !important;
}

input[type=file],
input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text] {
    background: #fff;
    border: 1px solid #333;
    border-radius: 0;
    color: #333;
    font-family: 'LG Smart Regular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 5px;
}

input[type=checkbox]{
    width: 22px;
    height: 22px;
}

textarea {
    border: 1px solid #333 !important;
    border-radius: 0 !important;
    color: #333 !important;
    font-size: 13px !important;
    padding: 0 10px !important;
}

h2 {
    font-family: 'LG Smart SemiBold', sans-serif;
    font-size: 1.5rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.75rem !important;
    letter-spacing: 0.009rem !important;
}

label {
    font-size: 13px;
    line-height: 1;
    color: #333;
    font-family: "LG Smart SemiBold", sans-serif;
    font-weight: 700;
}

.parrafo {
    font-family: 'LG Smart Regular', sans-serif;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 109.09%;
}

.btn {
    display: inline-block;
    min-width: 40px;
    padding: 6px 10px;
    border-radius: 0px !important;
    border: 1px solid transparent;
    font-family: 'LG Smart Bold', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
}

.btn.btn-danger {
    color: #fff !important;
    border-color: #a50034 !important;
    background: #a50034 !important;
}

.btn-outline-danger {
    color: #a50034 !important;
    border-color: #a50034 !important;
}

.btn-outline-danger:hover {
    color: #fff !important;
    background: #a50034 !important;
}


.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn.btn-flat {
    font-weight: 700 !important;
}

.input-group-text {
    background: #a50034 !important;
    /*background-color: #808080 !important;
    */
    color: #ffffff !important;
    border: 1px solid #333 !important;
    border-radius: 0 !important;
}

.react-select-container {
    border: 1px solid #000 !important;
    color: #000 !important;
    border-radius: 0 !important;
}

.textred {
    color: #a50034;
}

/* a {
    color: #212529 !important;
}

a:hover {
    color: #a50034;
} */